<template>
  <div>
    <notification-component type="list" />
  </div>
</template>

<script>
import NotificationComponent from "./components/NotificationComponent.vue";
export default {
  components: {
    NotificationComponent,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      count: null,
      notifications: [],
    };
  },
  // created() {
  //   this.getNotifications();
  // },
  methods: {
    getNotifications() {
      this.$store
        .dispatch("user/notification", this.userData?.id)
        .then((response) => {
          this.notifications = response.data.data;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
